<template>
  <div class="NewRenewalComponent d-flex justify-center align-center mt-2">
    <div class="NewRenewalTextContent active">{{ viewTitle }}</div>
    <div class="NewRenewalTextContent ml-15" @click="setModalCreateCompany()">
      Company
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";

export default {
  name: "TypeCreate",
  props: {
    viewTitle: {
      type: String,
    },
  },
  methods: {
    ...mapMutations(["setModalCreateCompany"]),
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/TypeBarStyle.less";
</style>